import React, { FC, PropsWithChildren, useEffect } from 'react'
import http from '../../services/http-common'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

const ErrorHandlingInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const authInterceptor = async (error: any) => {
      const data = error.response?.data

      if (error.response?.status === 404) {
        toast.error(data?.errorMessage)
      } else if (error.response?.status === 403) {
        toast.error('Unauthorized request')
        navigate('/not-authorized')
      }
      if (error.response && error.response.status === 500) {
        toast.error('Server error')
      } else {
        toast.error(data.errorMessage ?? 'An error occurred!')
      }
      return Promise.reject(error)
    }

    const interceptor = http.interceptors.response.use(async (response) => response, authInterceptor)

    return () => {
      http.interceptors.response.eject(interceptor)
    }
  }, [navigate])

  return <>{children}</>
}

export default ErrorHandlingInterceptor
