import '@sistemiv/s-components/dist/TC.css'
import { MsalProvider } from '@azure/msal-react'
import React, { FC } from 'react'
import AppRouter from './router/AppRouter'
import toast, { Toaster } from 'react-hot-toast'
import { QueryCache, QueryClient } from '@tanstack/query-core'
import { QueryClientProvider } from '@tanstack/react-query'
import { ServerError } from '@azure/msal-browser'

const App: FC<{ pca: any }> = ({ pca }) => {
  const handleError = (error: any): string => {
    let errorMessage = ''
    const response = error.response
    const data = response?.data
    const status = response?.status
    if (status === 404) {
      errorMessage = data?.message ?? 'Resource not found'
    } else if (status === 403) {
      errorMessage = 'Unauthorized request'
    } else {
      errorMessage = data?.errorMessage ?? 'An error occurred!'
    }
    return errorMessage
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
      mutations: {
        onError: (error) => {
          console.log(error)
          //toast.error(handleError(error))
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        toast.error(handleError(error))
      },
    }),
  })

  pca.handleRedirectPromise().catch((error: any) => {
    if (error instanceof ServerError) {
      window.location.reload()
    }
  })

  return (
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
        <Toaster position='bottom-right' reverseOrder={false} />
      </QueryClientProvider>
    </MsalProvider>
  )
}

export default App
